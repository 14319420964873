import cn from "classnames";
import { format } from "date-fns";
import { Card, Ratio } from "react-bootstrap";

import Link from "next/link";
import classes from "./styles.module.scss";

export default function ({ className = "px-3", title, topics = [], published, image, href, featured }) {
  const [topic] = topics;

  return (
    <Card as={Link} href={href} className={cn(className, classes.BlogPostCard)}>
      <Card.Img as={Ratio} aspectRatio={68}>
        <img src={image} alt="" />
      </Card.Img>
      <Card.Body>
        <Card.Header>
          <i />
          {topic && <span>{topic.name}</span>}
          <span>/</span>
          <span>
              {Date.parse(published)
                ? format(new Date(published), "d MMM yyyy")
                : published}</span>
        </Card.Header>
        <Card.Title as="h5" className="fw-bold mb-4">
          {title}
        </Card.Title>
        {featured && (
          <Card.Footer className="xsmall">
            <i /> Featured Blog
          </Card.Footer>
        )}
      </Card.Body>
    </Card>
  );
}
